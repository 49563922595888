import React, { useEffect } from 'react'
import Faq from '../../components/faq/Faq'

function FaqPage({setShow}) {

  return (
    <><Faq /></>
  )
}

export default FaqPage