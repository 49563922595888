import React from "react";
import titan from "../../../assets/img/Titans-600px.png";
import imachining from "../../../assets/img/imachining.jpg";
import DMG  from "../../../assets/img/DMG-MORI-600.png";
import MillTurn from "../../../assets/img/MillTurn.jpg";
import RightShortIntro from "./right-short-intro/RightShortIntro";
import LeftShortIntro from "./left-short-intro/LeftShortIntro";

const ShortInfo = () => {
  return (
    <>
      <RightShortIntro
        data={{
          url: titan,
          title: "TITANS of CNC & SolidCAM",
          desc: "The TITANS of CNC machining have joined forces with the best CAM software in the world!",
        }}
      />
      <LeftShortIntro
        data={{
          url: imachining,
          title: "HIGH PERFORMANCE CNC MACHINING",
          desc: "Easily handle complex geometry with unbeatable cycle time savings, unmatched tool life and patented technology wizard that dramatically increases your CNCs productivity.",
        }}
      />

      <RightShortIntro
        data={{
          url: MillTurn,
          title: "POWERFUL & EASY TO LEARN CAM SOLUTION",
          desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio, reprehenderit! Enim cum laboriosam ad voluptate facere labore cumque nobis architecto",
        }}
      />
      <LeftShortIntro
        data={{
          url: DMG,
          title: "SolidCAM & DMG MORI in the DMQP Program",
          desc: "SolidCAM announces an elevated partnership with DMG MORI",
        }}
      />
    </>
  );
};

export default ShortInfo;
