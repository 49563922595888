import React, { useEffect } from 'react'
import PhotoGallery from '../../components/photo-gallery/PhotoGallery'

function PhotoGalleryPage({setShow}) {

  return (
    <><PhotoGallery /></>
  )
}

export default PhotoGalleryPage