import React from "react";
import { BsArrowRight } from "react-icons/bs";
import {
  GiChestnutLeaf,
  GiFruitBowl,
  GiTomato,
  GiFertilizerBag,
} from "react-icons/gi";
import { Link } from "react-router-dom";

import "./ServiceList.css";
function ServiceList() {
  return (
    <>
      <section className="serviceList">
        <div className="container">
          <div className="fisherman-content">
            <h6 className="brandName">Solid CAM</h6>
            <h3 className="text-white">We have Support </h3>
            <p className="text-white" >
              Solid CAM Agrow engages in the manufacturing, formulating and
              exporting of Agrochemicals – Insecticides, Fungicides, Herbicides,
              Plant Growth Regulators / Micro Nutrients. Solid CAM has evolved
              as a new advanced growing company in the agro-chemical industry of
              India as well as Abroad with experienced visionary true leadership
              and enthusiastic team on path of going globally.
            </p>
          </div>
          <div className="serviceItemInfo">
            <div className="serviceItem">
              <div className="serviceItemIcon">
                01
              </div>
              <div className="serviceItemText">
                <h5>SolidCAM Subscription</h5>
                <p>Support</p>
                <Link to="/products">
                  <BsArrowRight />
                </Link>
              </div>
            </div>
            <div className="serviceItem">
              <div className="serviceItemIcon">
              02
              </div>
              <div className="serviceItemText">
                <h5>Technical Support</h5>
                <p>Support</p>
                <Link to="/products">
                  <BsArrowRight />
                </Link>
              </div>
            </div>
            <div className="serviceItem">
              <div className="serviceItemIcon">
              03
              </div>
              <div className="serviceItemText">
                <h5>Postprocessors </h5>
                <p>Support</p>
                <Link to="/products">
                  <BsArrowRight />
                </Link>
              </div>
            </div>
            <div className="serviceItem border-0">
              <div className="serviceItemIcon">
              04
              </div>
              <div className="serviceItemText">
                <h5>Documentation</h5>
                <p>Support</p>
                <Link to="/products">
                  <BsArrowRight />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ServiceList;
