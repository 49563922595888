import React, { useEffect } from 'react'
import Seller from '../../components/seller/Seller'

function SellerPage() {

  return (
    <><Seller /></>
  )
}

export default SellerPage