import React from 'react'
import SellerTestimonialUser from './seller-testimonial-user/SellerTestimonialUser'

function SellerTestimonial() {
  return (
    <>
        <SellerTestimonialUser />
    </>
  )
}

export default SellerTestimonial