import React from "react";
import Checkout from "../../components/checkout/Checkout";
import Breadcrumb from "../../shared/breadcrumb/Breadcrumb";

function CheckoutPage() {
  return (
    <>
      {/* <Breadcrumb title="Checkout" /> */}
      <Checkout />
    </>
  );
}

export default CheckoutPage;
