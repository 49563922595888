import React, { useEffect } from 'react'
import Reset from '../../components/reset/Reset'

function ResetPage({ setShow }) {

  return (
    <>
        <Reset />
    </>
  )
}

export default ResetPage