import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { base_url } from "../../server";
import axios from "axios";
import serve1 from "../../assets/img/serve3.jpg";
import serve2 from "../../assets/img/serve1.jpg";
import serve3 from "../../assets/img/serve2.jpg";
import { FaPlusCircle } from "react-icons/fa";

const Service = () => {
  const [categoriesDatas, setCateData] = useState(null);
  const baseUrl = base_url();
  const getcateData = async () => {
    try {
      const res = await axios.get(`${baseUrl}industry/public`, {
        withCredentials: true,
      });
      setCateData(res.data);
    } catch (error) {}
  };
  useEffect(() => {
    getcateData();
  }, []);
  return (
    <>
      <section className="servicePage">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="serveList">
                <div className="fisherman-content">
                  <h3>Our Services</h3>
                </div>
                <ul>
                  {categoriesDatas?.map((item, i) => {
                    return (
                      <li key={i}>
                        <Link to="/services">{item?.name} <FaPlusCircle /></Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="col-md-8">
              <div className="serviceCard">
                <img
                  src={serve1}
                  alt="945466780_service-det-01"
                  className="img-fluid"
                />
                <h4>Architecture</h4>
                <p>
                  Monsidering the physical, mental, and emotional needs of
                  people, interior designers use human-centered approaches to
                  address how we live today. Creating novel approaches to
                  promoting health, safety, and welfare, contemporary interiors
                  are increasingly inspired by biophilia as a holistic approach
                  to promoting health, safety, and welfare, contemporary
                  interiors are increasingly inspired by biophilia as a holistic
                  approach to design. By definition, interior design encompasses
                  diverse aspects of our environment. The discipline extends to
                  building materials and finishes; casework, furniture.
                </p>
                <div className="flex">
                  <img
                    src={serve2}
                    alt="945466780_service-det-01"
                    className="img-fluid"
                  />
                  <img
                    src={serve3}
                    alt="945466780_service-det-01"
                    className="img-fluid"
                  />
                </div>
                <p>
                  In design, we bring characteristics of the natural world into
                  built spaces, such as water, greenery, and natural light, or
                  elements like wood and stone. Encouraging the use of natural
                  systems and processes in design allows for exposure to nature,
                  and in turn, these design approaches improve health and
                  wellbeing. There are a number of possible benefits, including
                  reduced heart rate variability and pulse rates, decreased
                  blood pressure, and increased activity in our nervous systems,
                  to name a few.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Service;
