import React from "react";
import img1 from "../../../assets/--jskjjjj/junaid1.jpg";
import img2 from "../../../assets/--jskjjjj/junaid2.jpg";
import img3 from "../../../assets/--jskjjjj/junaid3.jpg";
import img4 from "../../../assets/--jskjjjj/junaid4.jpg";
function AddingShow() {
  return (
    <>
      <section className="numberSec">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-3">
                  <div className="numberCard">
                    <img src={img1} />
                    <h3>700</h3>
                    <h5>employees worldwide</h5>
                  </div>
                </div>
                <div className="col-3">
                  <div className="numberCard">
                    <img src={img2} />
                    <h3>462</h3>
                    <h5>total warehouses</h5>
                  </div>
                </div>
                <div className="col-3">
                  <div className="numberCard">
                    <img src={img3} />
                    <h3>10</h3>
                    <h5>countries presence</h5>
                  </div>
                </div>
                <div className="col-3">
                  <div className="numberCard">
                    <img src={img4} />
                    <h3>20+</h3>
                    <h5>processing plants</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AddingShow;
