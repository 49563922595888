import React from 'react'
import SolidShop from '../../components/solidShop/SolidShop'

const SolidShopPage = () => {
  return (
    <>
      <SolidShop />
    </>
  )
}

export default SolidShopPage