import React from "react";
import aboutIMG from "../../../assets/img/TRIMECH_SC_slide_bg_600.jpg";

const About = () => {
  return (
    <>
      <section className="aboutHome">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="aboutImg">
                <img src={aboutIMG} alt="About" className="img-fluid" />
              </div>
            </div>
            <div className="col-md-7">
              <div className="aboutText">
                <h6 className="brandName">Solid CAM</h6>
                <h3>PROVIDING EXCELLENCE IN CAD & CAM</h3>
                <p>
                  TriMech Becomes North America's Leading Provider of SolidCAM's
                  CAD/CAM Solutions for CNC Manufacturing
                </p>
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Enim
                  iste, veritatis hic pariatur rem totam? Odio, sed.
                  Voluptatibus officia sequi hic nulla vero, nostrum
                  perspiciatis, atque nam laboriosam nesciunt dolore!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
