import { BsCheckCircleFill } from "react-icons/bs";
import { FcProcess } from "react-icons/fc";
import { GiTireIronCross } from "react-icons/gi";
import { Link, useNavigate } from "react-router-dom";

function SussessMsg({
  plaecedData,
  cartValue,
  currencySymbol,
  proceedToPayment,
  payementMode,
  setSuccessModal
}) {
  const isLogin = window.localStorage.getItem("isLogin");
const navigate = useNavigate()
  const chengeRoute = () => {
    setSuccessModal(false)
    navigate("/");
  };
  return (
    <div
      className="modal fade show"
      id="exampleModal"
      tabIndex="-1"
      role="dialog"
      style={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "#808080a8",
      }}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" style={{ width: "500px" }} role="document">

        <div className="modal-content">
          <div style={{ display: "flex", justifyContent: "end",margin:"10px" }}>
            <GiTireIronCross onClick={chengeRoute}/>
          </div>
          <div className="modal-header d-block">
            <h5
              className="modal-title"
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: "45px",
              }}
              id="exampleModalLabel"
            >
              {payementMode === 'COD' ? <BsCheckCircleFill /> : <FcProcess />
              }
            </h5>
            <h5
              className="modal-title succesful"
              id="exampleModalLabel"
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: "25px",
              }}
            >
              {payementMode === 'COD' ? 'Order placed Successfully' : 'Order Transaction Initiated'}

            </h5>
          </div>
          {plaecedData?.orderList?.map((item) => {
            return (
              <div className="modal-body">
                <h6>Your Order Id : {item?.order_referenceNo}</h6>
                <h6>
                  Amount : {plaecedData?.currency?.symbol} {item?.grandTotal}
                </h6>
              </div>
            );
          })}
          {payementMode === "POD" && (
            <p
              onClick={() => proceedToPayment(plaecedData)}
              className="btn btn-outline-success"
              style={{ width: "200px", margin: "20px auto" }}
            >
              Proceed To Payment
            </p>
          )}

          {isLogin == "true" && (
            <div style={{ margin: "10px" }}>
              {plaecedData?.orderList?.map((item, i) => {
                return (
                  <Link
                    key={i}
                    to={`/order-detail/${item._id}`}
                    className="btn btn-outline-info"
                    style={{ width: "130px", margin: "0 5px" }}
                  >
                    Order Detail
                  </Link>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default SussessMsg;
