import React, { useEffect } from 'react'
import VideoGallery from '../../components/video-gallery/VideoGallery'

function VideoGalleryPage({setShow}) {

  return (
    <><VideoGallery /></>
  )
}

export default VideoGalleryPage