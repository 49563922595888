import React, { useEffect } from "react";
import TermsOfUse from "../../components/terms-of-use/TermsOfUse";
import Breadcrumb from "../../shared/breadcrumb/Breadcrumb";
// import Breadcrumb from "../../shared/breadcrumb/Breadcrumb";

function TermsOfUsePage({ setShow }) {
  return (
    <>
      {/* <Breadcrumb title="Terms & Condition" /> */}
      <TermsOfUse />
    </>
  );
}

export default TermsOfUsePage;
