import React, { useEffect, useState } from "react";
import AboutBanner from "./about-banner/AboutBanner";
import Breadcrumb from "../../shared/breadcrumb/Breadcrumb";
import AboutStory from "./about-story/AboutStory";
import AboutChoose from "./about-choose/AboutChoose";
import AboutCounters from "./about-counters/AboutCounters";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { base_url } from "../../server";
import GetTouch from "../home/get-touch/GetTouch";
import Network from "../home/network/Network";

function About() {
  const [data, setData] = useState();
  const baseUrl = base_url();
  const aboutData = async () => {
    try {
      const res = await axios.get(`${baseUrl}adminWeb_footer/get`);
      setData(res.data);
    } catch (error) {
      console.log("server-error");
    }
  };
  useEffect(() => {
    aboutData();
  }, []);
  const { t, i18n } = useTranslation();
  return (
    <>
      <Breadcrumb title="About Us" t={t} />
      <main className="main about">
        {/* <AboutBanner /> */}
        <AboutStory data={data} t={t} />
        <Network />
        <AboutChoose t={t} />
        <GetTouch />

        <div className="map">
          <div className="container">
            <div className="mapContent">
              <h3>WE ARE ON THE WORLD </h3>
              Our Parent company ``Solid CAM`` having 100% focused on Export of
              Agro chemicals with giving support of quality product,
              comprehensive data packages & new technology based formulation &
              combination products.
            </div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.431846591954!2d77.2597101!3d28.55679230000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce38abca3560d%3A0xbd54bc439e45d16a!2sAbaris%20Softech%20Pvt%20Ltd.!5e0!3m2!1sen!2sin!4v1730454525844!5m2!1sen!2sin"
              width={"100%"}
              height={450}
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
      </main>
    </>
  );
}

export default About;
