import React from 'react';
import aboutIMG from "../../../../assets/img/TRIMECH_SC_slide_bg_600.jpg";
import { Link } from 'react-router-dom';


const RightShortIntro = ({data}) => {
  return (
    <>
      <section className="aboutHome" style={{backgroundColor:"#131111"}}>
        <div className="container">
          <div className="row">
            
            <div className="col-md-7">
              <div className="aboutText">
                <h6 className="brandName">Solid CAM</h6>
                <h3 className='text-white'>{data.title}</h3>
                <p className='text-white'>
                  {data.desc}
                </p>
                <p  className='text-white'>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Enim
                  iste, veritatis hic pariatur rem totam? Odio, sed.
                  Voluptatibus officia sequi hic nulla vero, nostrum
                  perspiciatis, atque nam laboriosam nesciunt dolore!
                </p>
                <Link to="/products" className="buttonBG">Learn More</Link>

              </div>
            </div>
            <div className="col-md-5">
              <div className="aboutImg">
                <img src={data.url} alt="About" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default RightShortIntro