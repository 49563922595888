import React from "react";
import Careers from "../../components/careers/Careers";
import Breadcrumb from "../../shared/breadcrumb/Breadcrumb";

function CareersPage() {
  return (
    <>
      <Breadcrumb title="Careers" />
      <Careers />
    </>
  );
}

export default CareersPage;
