import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useSendCommentBlogsMutation } from "../../products/productSlice";

function BlogCommentForm() {
  const [sendComment, { isLoading }] = useSendCommentBlogsMutation()
  const params = useParams()
  const [state, setState] = useState({
    blog_Id: params.id,
    user_id: window.localStorage.getItem('user_id'),
    name: "",
    review: "",
    url: "",
  })

  const onchangeHandle = (e) => {
    const clone = { ...state }
    clone[e.target.name] = e.target.value
    setState(clone)
  }

  const sendData = () => {
    sendComment(state);
    setState({
      blog_Id: params.id,
      user_id: window.localStorage.getItem('user_id'),
      name: "",
      review: "",
      url: "",
    })
  }

  return (
    <>
      <div className="blogFormSec">
        <h4>Leave a Reply</h4>
        <div className="blogFormInfo">
          <p>
            Your email address will not be published. Required fields are marked
            *
          </p>

          {isLoading && <div className="preloaderCount">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>}

          <form className="mb-3">
            <div className="form-group mb-3">
              <label htmlFor="comment">Comment</label>
              <textarea
                type="text"
                placeholder="Enter Your Name"
                className="form-control"
                rows="4"
                name="review"
                value={state.review}
                onChange={onchangeHandle}
              ></textarea>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                placeholder="Enter Your Name"
                className="form-control"
                name="name"
                value={state.name}
                onChange={onchangeHandle}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="website">Website</label>
              <input
                type="url"
                placeholder="Enter Website URL"
                className="form-control"
                name="url"
                value={state.url}
                onChange={onchangeHandle}
              />
            </div>

            <div className="form-group mb-3">
              <button className="btn secondaryBtn" type="button" onClick={sendData}>post comment</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default BlogCommentForm;
