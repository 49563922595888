import React from "react";
import Service from "../../components/service/Service";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../../shared/breadcrumb/Breadcrumb";

const ServicePage = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Breadcrumb title="Our Services" t={t} />
      <Service />
    </>
  );
};

export default ServicePage;
