import React from "react";
import { Link } from "react-router-dom";
import facebook from "../../assets/img/social/facebook.png";
import whatsapp from "../../assets/img/social/whatsapp.png";
import instagram from "../../assets/img/social/instagram.png";
import ReactWhatsapp from "react-whatsapp";
const socialMediaUrl = [
  {
    id: "1",
    imgUrl: instagram,
    socialLink: "https://www.instagram.com/",
  },
  {
    id: "2",
    imgUrl: facebook,
    socialLink: "https://www.facebook.com/",
  },
  {
    id: "3",
    imgUrl: whatsapp,
    socialLink:
      "https://api.whatsapp.com/send/?phone=8851746286-&text&type=phone_number&app_absent=0",
  }
];
function SocialMedia() {
  return (
    <div className="socialIconBox">      
      {socialMediaUrl.map((item, key) => {
        return (
          <div className="socialIcon" key={item.id}>
            <a href={item?.socialLink} target="_blank">
              <img src={item.imgUrl} alt="socialLink" width={30} />
            </a>
          </div>
        );
      })}
    </div>
  );
}

export default SocialMedia;
