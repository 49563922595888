import { useState } from "react";
import React, { createContext, useContext, useEffect } from "react";
import { Provider } from "react-redux";
import { productItemHome } from "../../pages/home";
import { productData } from "../../pages/products/mockData";
import { store } from "../store";
import Banner from "./banner/Banner";
import BestSeller from "./best-seller/BestSeller";
import Brands from "./brands/Brands";
import CategoriesItems from "./categories-items/CategoriesItems";
import CostInfo from "./cost-info/CostInfo";
import FeaturedProducts from "./featured-products/FeaturedProducts";
import Network from "./network/Network";
import ProductsCategory from "./products-category/ProductsCategory";
import ProductItem from "./products/product/ProductItem";
import Products from "./products/Products";
import ServiceList from "./service-list/ServiceList";
import TodayDeals from "./today-deals/TodayDeals";
import TrendingProducts from "./trending-products/TrendingProducts";
import AddingShow from "./network/AddingShow";
import CatagProduct from "./CatagPro/CatagProduct";
import axios from "axios";
import { base_url } from "../../server";
import PopularProduct from "./popular";
import Faq from "./faq/Faq";
import GetTouch from "./get-touch/GetTouch";

import etg from "../../assets/img/brands/etg.png";
import kynoch from "../../assets/img/brands/kynoch.png";
import falcon from "../../assets/img/brands/falcon.png";
import oemff from "../../assets/img/brands/oemff.png";
import artboard from "../../assets/img/brands/artboard.jpg";
import bayer from "../../assets/img/brands/bayer.jpg";
import dhanuka from "../../assets/img/brands/dhanuka.jpg";
import namdhari from "../../assets/img/brands/namdhari.jpg";

import bgAttach from "../../assets/img/banner/bgAttach.jpg";

import { Link } from "react-router-dom";
import About from "./about/About";
import ShortInfo from "./short-info/ShortInfo";

function Home() {
  const productData = useContext(productItemHome);
  const baseUrl = base_url();
  const [data, setData] = useState(null);
  const getata = async () => {
    try {
      const res = await axios.get(`${baseUrl}category/filter/categ`, {
        withCredentials: true,
      });
      setData(res.data);
    } catch (error) {}
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getata();
  }, []);
  return (
    <>
      <Banner />
      <About />
      <ServiceList />
      <ProductsCategory />
      <PopularProduct />
      {/* <Products productData={productData} /> */}

      <FeaturedProducts />

      {data &&
        data?.slice(0, 3).map((item, i) => {
          return <CatagProduct key={i} item={item} />;
        })}

      {/* <TrendingProducts /> */}
      {/* <TodayDeals /> */}
      <section className="sellerCategorySec d-none">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <CategoriesItems />
            </div>
          </div>
        </div>
      </section>
      <ShortInfo />
      {/* <BestSeller /> */}
      <Network />
      <Brands />
      <Faq />
      <AddingShow />
      <GetTouch />
      <div className="map">
        <div className="container">
          <div className="mapContent">
            <h3>WE ARE ON THE WORLD </h3>
            The core strategy of SolidCAM focuses on integration in the leading 3D CAD systems, SOLIDWORKS and Autodesk Inventor. This strategy has created major growth and established SolidCAM as both the leader in Integrated CAM, as well as one of the most powerful CAM systems available.


          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.431846591954!2d77.2597101!3d28.55679230000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce38abca3560d%3A0xbd54bc439e45d16a!2sAbaris%20Softech%20Pvt%20Ltd.!5e0!3m2!1sen!2sin!4v1730454525844!5m2!1sen!2sin"
            width={"100%"}
            height={450}
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
      </div>

      {/* <AddingShow /> */}

      {/* <HandicraftImage /> */}
      {/* <CostInfo /> */}
    </>
  );
}

export default Home;
