import React from "react";
import RetunsRefundCancellation from "../../components/returns-refund-cancellation/RetunsRefundCancellation";

function ReturnsRefundCancellationPage({ setShow }) {
  return (
    <div>
      <RetunsRefundCancellation />
    </div>
  );
}

export default ReturnsRefundCancellationPage;
