import React from "react";
import CIMCO from "../../assets/img/catebann/CIMCO-Edit___Machine_Simulation.jpg";
const solidShopModules = [
  {
    id: "1",
    name: "SolidShop Editor",
    description:
      "A powerful CNC editor able to manipulate NC code and automate programming tasks. Features include parametric code simulation, file comparison, programming of complex cycles, G-code transformations, CNC code conversion, and cycle simulations for turning and milling.",
  },
  {
    id: "2",
    name: "G-code Machine Simulator",
    description:
      "An add-on for the SolidShop Editor for verifying G-code for 2 to 5-axis CNC machines and lathes with C-axis and turret. Provides collision detection, syntax checking, envelope studies, offline training, and material removal simulation.",
  },
  {
    id: "3",
    name: "SolidShop PDM",
    description:
      "Product Data Management module for centralized manufacturing document management. Offers SSOT for all documents, supports revision control, advanced access management, and integrates tightly with SolidCAM.",
  },
  {
    id: "4",
    name: "SolidShop DNC",
    description:
      "Enables robust communication with CNC controls to upload/download NC programs, supporting multiple protocols, and integrates with SolidShop PDM for traceability and version control.",
  },
  {
    id: "5",
    name: "SolidShop Machine Monitoring",
    description:
      "Real-time machine monitoring solution with ERP, FMS, Power BI, SQL databases, and TCP integration, capable of tracking production order information and enabling MES functionality.",
  },
  {
    id: "6",
    name: "SolidShop Planner",
    description:
      "Advanced production planning module supporting backward and forward planning for production scheduling, real-time tracking of orders, and optimization of machine workloads.",
  },
];

const SolidShop = () => {
  return (
    <>
      <section className="solidSec">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="solidIntro fisherman-content">
                <h3>SolidShop – the Eco-System for Digital Manufacturing</h3>
                <p>
                  SolidShop is SolidCAM’s ecosystem for optimal management of
                  shop floor data. It provides a globally proven set of software
                  modules for shop digitization.
                </p>
                <p>
                  SolidShop encompasses document and manufacturing process
                  management (PDM), bi-directional communication with CNC
                  machines (DNC), real-time CNC machine monitoring (MDC), and
                  tracking & scheduling of production orders and jobs in
                  real-time.
                </p>
                <p>
                  For many years, manufacturers have been waiting for affordable
                  shop floor digitization, that is proven in the field,
                  compatible with older and newer CNC machines, and with shorter
                  implementation times – SolidShop is SolidCAM’s answer to all
                  these demands.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="solidIntroImg">
                <img src={CIMCO} alt="CIMCO" className="img-fluid" />
                <h3>CONTROL. MONITOR. MAXIMIZE PRODUCTIVITY.</h3>
              </div>
            </div>
          </div>
          <div className="enhancePoint">
            <div className="row">
              {solidShopModules.map((item) => {
                return (
                  <div key={item.id} className="col-lg-4">
                    <div className="enhanceList">
                      <h4>{item.name}</h4>
                      <p>{item.description}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SolidShop;
