import product1 from "../../assets/img/products/1.jpg";
import product2 from "../../assets/img/products/2.jpg";
import product3 from "../../assets/img/products/3.jpg";
import product4 from "../../assets/img/products/4.jpg";
import product5 from "../../assets/img/products/5.jpg";
export const cartData = [
    {
        id: "1",
        title: "saaho tomato seeds",
        price: "ZK 9,499",
        url: product1,
    },
    {
        id: "2",
        title: "godrej gracia",
        price: "ZK 5,499",
        url: product2,
    },
    {
        id: "3",
        title: "planofix growth promoter",
        price: "ZK 7,199",
        url: product3,
    },
    {
        id: "4",
        title: "syngenta ",
        price: "ZK 9,499",
        url: product4,
    },
    {
        id: "5",
        title: "vanproz v-bind",
        price: "ZK 5,699",
        url: product5,
    }
]