import React, { useEffect } from 'react'
import BlogDetail from '../../components/blog-detail/BlogDetail'

function BlogDetailPage({setShow}) {

  return (
    <>
    
    <BlogDetail /></>
  )
}

export default BlogDetailPage