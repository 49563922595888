import React, { useEffect } from "react";
import SellWithUs from "../../components/sell-with-us/SellWithUs";

function SellWithUsPage({ setShow }) {
 
  return (
    <>
      <SellWithUs />
    </>
  );
}

export default SellWithUsPage;
