import React, { useEffect } from "react";
import Testimonial from "../../components/testimonial/Testimonial";

function TestimonialPage() {

  return (
    <>
      <Testimonial />
    </>
  );
}

export default TestimonialPage;
